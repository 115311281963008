@media (max-width: 768px) {  
    .center-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .center-mobile button {
        width: 100%;
        max-width: 300px;
    }
}